<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
        <v-col cols="12" lg="8">
            <BaseCard heading="Configurar parametros de la App">
                <v-row>
                    <v-col cols="12" lg="6">
                        <div class="mt-0">
                            <v-text-field
                                v-model="minDeposito"
                                label="Monto minimo de depositos"
                                background-color="transparent"
                                prepend-icon="mdi-currency-usd"
                                @keyup="cleanAlert"
                            ></v-text-field>
                            <v-text-field
                                v-model="minRetiro"
                                label="Monto minimo de retiros"
                                background-color="transparent"
                                prepend-icon="mdi-currency-usd"
                                @keyup="cleanAlert"
                            ></v-text-field>
                            <!-- <v-text-field
                                v-model="minInversion"
                                label="Monto minimo de inversiones"
                                background-color="transparent"
                                prepend-icon="mdi-currency-usd"
                                @keyup="cleanAlert"
                            >
                            </v-text-field> -->
                            <v-sheet class="pl-3">
                                <v-switch
                                v-model="playAutomatico"
                                inset
                                :label="`Play automático : ${playStatus}`"
                                @change="cleanAlert"
                                ></v-switch>
                            </v-sheet>
                            <v-sheet class="pl-3">
                                <v-switch
                                v-model="aprobarAutomatico"
                                inset
                                :label="`Aprobar automático TRON-TRC20: ${aprobarStatus}`"
                                @change="cleanAlert"
                                ></v-switch>
                            </v-sheet>
                            <v-sheet class="pl-3">
                                <v-switch
                                v-model="rechazoAutomatico"
                                inset
                                :label="`Rechazo automático : ${rechazoStatus}`"
                                @change="cleanAlert"
                                ></v-switch>
                            </v-sheet>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <div class="mt-0">
                            <v-text-field
                                v-model="porcReferidos1"
                                label="Porcentaje referidos 1er Nivel"
                                background-color="transparent"
                                prepend-icon="mdi-percent-outline"
                                @keyup="cleanAlert"
                            ></v-text-field>
                            <v-text-field
                                v-model="porcReferidos2"
                                label="Porcentaje referidos 2do Nivel"
                                background-color="transparent"
                                prepend-icon="mdi-percent-outline"
                                @keyup="cleanAlert"
                            ></v-text-field>
                            <v-text-field
                                v-model="porcReferidos3"
                                label="Porcentaje referidos 3er Nivel"
                                background-color="transparent"
                                prepend-icon="mdi-percent-outline"
                                @keyup="cleanAlert"
                            ></v-text-field>
                            <v-text-field
                                v-model="diasRechazo"
                                label="Dias rechazo automatico depositos"
                                background-color="transparent"
                                prepend-icon="mdi-timer-cancel-outline"
                                @keyup="cleanAlert"
                            >
                            </v-text-field>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="12" style="text-align:center">
                        <div>
                            <v-alert
                            text
                            dense
                            type="success"
                            border="left"
                            class="py-5"
                            v-if="viewSuccess"
                            ><b>!Atención!</b> {{msgSuccess}}.</v-alert>
                            <v-alert
                                text
                                dense
                                type="error"
                                border="left"
                                class="py-5"
                                v-if="viewError"
                            ><b>!Atención!</b> {{msgError}}.</v-alert>
                        </div>
                        <button 
                        type="button" 
                        class="btn btn-rounded btn-sm btn-primary w-50 subtitle-1 font-weight-medium" 
                        style="min-width: 250px;"
                        :disabled="loading"
                        @click="saveUser"
                        data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i>"
                        >
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading"></span>
                            Guardar 
                        </button>
                    </v-col>
                </v-row>
            </BaseCard>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import VsToast from '@vuesimple/vs-toast'

export default {
  name: "Configuracion",

  data: () => ({
    usrToken: '',
    viewSuccess: false,
    msgSuccess: '',
    viewError: false,
    msgError: '',
    minDeposito: 0,
    minRetiro: 0,
    minInversion: 0,
    playAutomatico: false,
    porcReferidos1: 0,
    porcReferidos2: 0,
    porcReferidos3: 0,
    diasRechazo: 0,
    rechazoAutomatico: false,
    aprobarAutomatico: false,
    titleConfig: 'Configuracion de la App',
    loading: false,
  }),
  components: {},
  computed: {
      playStatus () {
        return this.playAutomatico ? 'Activado' : 'Desactivado'
      },
      rechazoStatus () {
        return this.rechazoAutomatico ? 'Activado' : 'Desactivado'
      },
      aprobarStatus () {
        return this.aprobarAutomatico ? 'Activado' : 'Desactivado'
      },
    },

    watch: {
    },

    async created() {
        this.usrToken = localStorage.getItem("usrToken")
        this.getConfig()
    },

    methods: {
        async saveUser() {
            this.loading= true
            this.viewError= false
            this.viewSuccess= false
            await axios({
                method: 'PUT',
                url: `${process.env.VUE_APP_API_URL}/api/config/1`,
                params: {    
                    va_min_deposito: this.minDeposito,
                    va_min_retiro: this.minRetiro,
                    va_min_inversion: this.minInversion,
                    va_porc_referidos_1: this.porcReferidos1,
                    va_porc_referidos_2: this.porcReferidos2,
                    va_porc_referidos_3: this.porcReferidos3,
                    va_dias_rechazar_despositos: this.diasRechazo,
                    va_play_automatico: !this.playAutomatico ? 0 : 1,
                    va_rechazo_automatico: !this.rechazoAutomatico ? 0 : 1,
                    va_aprobar_automatico: !this.aprobarAutomatico ? 0 : 1
                },
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respConfig = response.data
                console.log('respConfig...', respConfig);
                if (!respConfig.success){
                    VsToast.show({
                        title: this.titleConfig,
                        message: 'No se han podido guardar los datos de configuración.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.msgError = respConfig.data.msg
                    this.viewError = true
                }
                else {
                    console.log('respConfig Succes: ', respConfig)
                    VsToast.show({
                        title: this.titleConfig,
                        message: 'Configuración guardada exitosamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                this.viewSuccess = true
                this.msgSuccess = respConfig.msg
                setTimeout(() => (this.loading = false), 3000)
            })
            .catch(error => {
                VsToast.show({
                    title: this.titleConfig,
                    message: 'Error al guardar los datos de configuración.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                console.error('respConfig Error: ', error)
                this.msgError = (error.response && error.response.data) ? error.response.data.message : error.message
                this.viewError = true
                setTimeout(() => (this.loading = false), 3000)
            })
        },

        async getConfig () {
            await axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/config`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respConfig = response.data
                console.log('respConfig...', respConfig);
                if (!respConfig.success){
                    VsToast.show({
                        title: this.titleConfig,
                        message: 'No se han podido cargar los datos de configuración.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                else {
                    console.log('respConfig Succes: ', respConfig)
                    this.minDeposito= respConfig.data.va_min_deposito
                    this.minRetiro= respConfig.data.va_min_retiro
                    this.minInversion= respConfig.data.va_min_inversion
                    this.porcReferidos1= respConfig.data.va_porc_referidos_1
                    this.porcReferidos2= respConfig.data.va_porc_referidos_2
                    this.porcReferidos3= respConfig.data.va_porc_referidos_3
                    this.diasRechazo= respConfig.data.va_dias_rechazar_despositos
                    this.playAutomatico= respConfig.data.va_play_automatico === 1 ? true : false
                    this.rechazoAutomatico= respConfig.data.va_rechazo_automatico === 1 ? true : false
                    this.aprobarAutomatico= respConfig.data.va_aprobar_automatico === 1 ? true : false
                }
            })
            .catch(error => {
                VsToast.show({
                    title: this.titleConfig,
                    message: 'Error al, cargar los datos de configuración.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                console.error('respConfig Error: ', error)
            })
        },

        cleanAlert() {
            this.viewError = false
            this.viewSuccess = false
        }
    },
};
</script>

<style>

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}

.btn-primary, .btn-info, .btn-default, .btn-warning, .btn-success, .btn-danger, .btn-outline-primary:hover, .btn-check:checked+.btn-outline-primary, .btn-check:checked+.btn-outline-warning, .btn-check:checked+.btn-outline-success, .btn-check:checked+.btn-outline-danger, .btn-outline-warning:hover, .btn-outline-danger:hover, .btn-outline-info:hover, .btn-outline-success:hover, .btn-primary.active, .btn-info.active, .btn-info:focus, .btn-info:disabled, .btn-success:disabled, .btn-primary:disabled, .btn-danger.active, .btn-success.active, .btn-success:focus, .btn-danger:focus, .btn-primary:focus, .show>.btn-danger.dropdown-toggle, .show>.btn-primary.dropdown-toggle, .show>.btn-info.dropdown-toggle, .show>.btn-success.dropdown-toggle, .show>.btn-warning.dropdown-toggle {
    color: #fff;
}

.btn-success:hover{
    background-color: #e8fdeb;
    border-color: rgba(6,215,156, 1);
    color:rgba(6,215,156, 1)!important
}

.saldo{
    background-color: #e8fdeb;
    border-color: #e8fdeb;
    color:rgba(6,215,156, 1)!important

}
</style>